.hero {

    .split-item {
		margin-bottom: 32px;
	}
}

@include media( '<=medium' ) {

	.hero {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: 64px;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: 64px;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

    .hero {

        .split-item {
			margin-bottom: 48px;
		}

        .split-wrap {

            .split-item {
                align-items: initial;
            }
        }
    }
}