// Pricing (core/sections/_pricing.scss) -----------------------
$pricing--padding-t__mobile:		null;		// pricing, top padding (mobile)
$pricing--padding-b__mobile: 		null;		// pricing, bottom padding (mobile)
$pricing--padding-t__desktop: 		null;		// pricing, top padding (desktop)
$pricing--padding-b__desktop: 		null;		// pricing, bottom padding (desktop)
$pricing-header--padding__mobile: 	null;		// pricing header, bottom padding (mobile)
$pricing-header--padding__desktop:	48px;		// pricing header, bottom padding (desktop)
$pricing-items--padding__mobile: 	null;		// padding between pricing items (mobile)
$pricing-items--padding__desktop: 	null;		// padding between pricing items (desktop)
$pricing-item--width: 				344px;		// pricing item, width
$pricing-item--inner-padding-v: 	24px;		// pricing item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$pricing-item--inner-padding-h: 	24px;		// pricing item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
$pricing-slider--max-w:             320px;      // pricing slider, max-width