.split-wrap {

	&:not(.invert-desktop) .split-item:nth-child(even),
	&.invert-desktop .split-item:nth-child(odd) {

		.split-item-image {

			&.is-3d {

				img,
				svg,
				video {
					box-shadow: -12px 32px 88px rgba(get-color(dark, 1), .24);
                    transform: perspective(1000px) rotateY(14deg) scaleY(0.94) scaleX(1.04) translateX(3.5%);
				}
			}
		}
	}

	&:not(.invert-desktop) .split-item:nth-child(odd),
	&.invert-desktop .split-item:nth-child(even) {

		.split-item-image {

			&.is-3d {

				img,
				svg,
				video {
					box-shadow: 12px 32px 88px rgba(get-color(dark, 1), .24);
                    transform: perspective(1000px) rotateY(-14deg) scaleY(0.94) scaleX(1.04) translateX(-3.5%);
				}
			}
		}
	}
}